<script setup>
    import { ref, watch } from 'vue';
    import { useLayoutStore } from '~/stores/layout.store';
    import { useSettingsStore } from '~/stores/settings.store';
    import { useSsrFetch } from "~/composables/useFetch";
    import { useMetaStore } from '~/stores/meta.store'

    const props = defineProps({
        data: {}
    });
    const metaStore = useMetaStore();
    const layoutStore = useLayoutStore();

    const teammate = ref([])
    const cities = ref([])
    const languages = ref([]);
    const filters = ref({ city_id: null, lang_id: null });
    const pageData = ref(props.data);


    const fetchedTeammate = await layoutStore.fetchTeammate();
    const fetchedHasOfficeCities = await layoutStore.fetchHasOfficeCities();

    teammate.value = fetchedTeammate?.data;
    cities.value = fetchedHasOfficeCities
    languages.value = layoutStore.languages;
    watch(() => props.data, () => pageData.value = props.data);

    const meta = ref({});
    meta.value = fetchedTeammate.meta
    metaStore.setMeta({ ...meta.value });

    const setCityId = (id) => {
        if (!id || filters.value.city_id == id) {
            return filters.value.city_id = null
        }
        return filters.value.city_id = id
    }

    const setLanguageId = (id) => {
        if (!id || filters.value.lang_id == id) {
            return filters.value.lang_id = null
        }
        return filters.value.lang_id = id
    }

    const filterTeammate = () => {
        let filtered = fetchedTeammate?.data;

        if (filters.value.city_id) {
            filtered = filtered.filter(mate => mate.city_id === filters.value.city_id);
        }
        if (filters.value.lang_id) {
            filtered = filtered.filter(mate => mate.supported_languages.some(l => l._id == filters.value.lang_id));
        }

        teammate.value = filtered;
    };

    watch(filters, filterTeammate, { deep: true });

    const settingStore = useSettingsStore()
    const whatsapp = settingStore.settings.social_media_whatsapp;
</script>

<template>
    <LayoutAppHead></LayoutAppHead>
    <NuxtLayout name="desktop-default">
        <main>
            <section>
                <div class="relative mx-auto mb-2">
                    <div class="relative h-60"></div>
                    <div class="absolute inset-0 bg-indigo-950 opacity-50 rounded-md"></div>
                    <div class="absolute bottom-1/4 p-5 flex flex-col justify-center items-center w-full">
                        <h2 class="text-white text-3xl font-medium tracking-wide mb-5">Meet Our Agents</h2>
                        <div class="p-2 bg-black/60 rounded-xl flex gap-2">
                            <button @click="setCityId(null)" type="button"
                                :class="['text-sm py-1 px-4 rounded-lg', !filters.city_id ? 'bg-white text-black' : 'text-gray-400 fs-2']">
                                All
                            </button>
                            <button v-for="(city, i) in cities" :key="i" @click="setCityId(city._id)" type="button"
                                :class="['text-sm p-2 rounded-lg', filters.city_id == city._id ? 'bg-white text-black' : 'text-gray-400']">
                                {{ city.name }}
                            </button>
                        </div>
                    </div>
                </div>

                <div class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8 mt-3 mb-8">
                    <div class="flex gap-2 justify-center items-center">
                        <button v-for="(lang, i) in languages" :key="i" @click="setLanguageId(lang._id)" type="button"
                            :class="['text-sm p-2 text-gray-400', filters.lang_id == lang._id ? 'text-indigo-900' : 'text-gray-400']">
                            {{ lang.name ?? lang.code }}
                        </button>
                    </div>

                    <div class="grid grid-cols-4 gap-4 mt-8">
                        <template v-for="(mate, i) in teammate" :key="i">
                            <div class="overflow-hidden cursor-pointer"
                                @click="$router.push(localePath({ name: 'slug', params: { slug: mate.slug } }))">
                                <div class="relative group overflow-hidden">
                                    <img :src="`${mate.picture}?w=600&h=1000`" alt=""
                                        class="w-80 h-72 object-cover rounded-xl">
                                    <div
                                        class="transform translate-y-20 ease-in-out invisible absolute bottom-0 group-hover:visible group-hover:translate-y-0 transition w-full duration-300">
                                        <div
                                            class="flex flex-col bg-gradient-to-t from-indigo-900 to-rgb(49 46 129 / 0.5) h-20 rounded-b-xl justify-center">
                                            <div class="flex gap-2 justify-center">
                                                <a :href="whatsapp" target="_blank"
                                                    class="w-9 h-9 bg-green-500 rounded-full text-center flex justify-center items-center">
                                                    <svg viewBox="0 0 32 32" class="w-6 h-6 fill-white">
                                                        <path
                                                            d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                                                            fill-rule="evenodd">
                                                        </path>
                                                    </svg>
                                                </a>
                                                <a href="javascript:void(0)"
                                                    class="w-9 h-9 bg-white rounded-full text-center flex justify-center items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                        fill="currentColor" class="w-6 h-6 fill-indigo-900">
                                                        <path fill-rule="evenodd"
                                                            d="M17.834 6.166a8.25 8.25 0 1 0 0 11.668.75.75 0 0 1 1.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0 1 21.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 1 1-.82-6.26V8.25a.75.75 0 0 1 1.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 0 0-2.416-5.834ZM15.75 12a3.75 3.75 0 1 0-7.5 0 3.75 3.75 0 0 0 7.5 0Z"
                                                            clip-rule="evenodd" />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center text-indigo-900 text-base font-medium leading-tight mt-4">{{
                                    mate.name }}</div>
                                <p class="text-center text-gray-500 text-sm leading-tight mt-2">{{ mate.position }}
                                </p>
                            </div>
                        </template>
                    </div>
                </div>
            </section>
        </main>
    </NuxtLayout>
</template>